<template>
  <div>
    <bottom-sheet :dialog="createAlbumSheet" @close="closeCreateAlbumSheet">
      <v-form @submit.prevent="handleSubmit">
        <v-row no-gutters>
          <v-col cols="12">
            <app-text-field
              v-model="title"
              placeholder="Título do Album (obrigatório)"
            ></app-text-field>
            <app-text-field
              v-model="description"
              placeholder="DEscrição"
            ></app-text-field>
          </v-col>

          <v-col>
            <app-btn
              :disabled="formError"
              type="submit"
              lIcon="check"
              color="primary"
              block
              >Criar</app-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </bottom-sheet>
    <app-btn-fab
      position="right-bottom"
      icon="plus"
      color="primary"
      @click="openCreateAlbumSheet"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useCollection } from '@/composables'
import { fbTimestamp } from '@/plugins/firebase'
import store from '@/store'
import { BottomSheet, useDialogs } from '@/components/layout/dialogs'
export default {
  name: 'CreateAlbum',

  components: {
    BottomSheet
  },

  props: {
    loggedUser: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const title = ref('')
    const description = ref('')
    const { error, addDoc } = useCollection('albums')

    const {
      dialog: createAlbumSheet,
      handleOpen: openCreateAlbumSheet,
      handleClose: closeCreateAlbumSheet
    } = useDialogs()

    const formError = computed(() => {
      return title.value === ''
    })

    const handleSubmit = async () => {
      store.dispatch('home/setOverlayLoading', true)
      const res = await addDoc({
        coverURL:
          'https://firebasestorage.googleapis.com/v0/b/components-80659.appspot.com/o/default%2Fdefault-profile-icon-16.jpg?alt=media&token=c070e80c-8e6c-44c1-ad79-6263efb1f6dd',
        title: title.value,
        description: description.value,
        userId: props.loggedUser.uid,
        photos: [],
        createdAt: fbTimestamp(),
        cover: 0
      })

      store.dispatch('home/setOverlayLoading', false)
      closeCreateAlbumSheet()
      console.log('cria album', title.value, res)
    }

    return {
      formError,
      error,
      title,
      description,
      createAlbumSheet,
      openCreateAlbumSheet,
      closeCreateAlbumSheet,
      handleSubmit
    }
  }
}
</script>

<style></style>
