<template>
  <div>
    <div v-if="albums && albums.length < 1">
      <no-item></no-item>
    </div>
    <div class="d-flex flex-wrap justify-space-around">
      <image-thumb
        v-for="album in albums"
        :key="album.id"
        :title="album.title"
        :coverUrl="
          album.photos && album.photos.length > 0
            ? album.photos[album.cover].coverUrl
            : 'https://firebasestorage.googleapis.com/v0/b/components-80659.appspot.com/o/default%2Fdefault-album-photo-cover.PNG?alt=media&token=a94de3c3-c303-49af-ba73-e0c7e9144e09'
        "
        @handleClick="
          $router.push({ name: 'AlbumDetails', params: { id: album.id } })
        "
      ></image-thumb>
    </div>
  </div>
</template>

<script>
import { ImageThumb } from '@/components/layout/cards'
import { NoItem } from '@/components/layout/error'
export default {
  name: 'Albums',

  components: {
    ImageThumb,
    NoItem
  },

  props: {
    albums: {
      type: Array,
      default: () => []
    }
  },

  setup() {
    const handleClick = (data) => {
      console.log(data)
    }

    return { handleClick }
  }
}
</script>

<style></style>
