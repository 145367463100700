<template>
  <div class="mx-3">
    <h1>Album {{ album ? album.title : 'Album' }}</h1>
    <div v-if="error">{{ error }}</div>
    <div v-if="album && album.photos.length < 1">
      Nenhuma foto ainda neste album.
    </div>
    <div v-if="album" class="d-flex flex-wrap justify-space-between">
      <div v-for="(img, i) in album.photos" :key="img.id">
        <image-thumb
          :coverUrl="img.coverUrl"
          @handleClick="viewImage(img)"
        ></image-thumb>
        <div v-if="i === album.cover">Capa</div>
        <div v-else>
          <app-btn x-small lIcon="pencil" @click="handleClick(i)">
            Tornar foto de capa
          </app-btn>
        </div>
      </div>
      <add-image :loggedUser="loggedUser" :album="album"></add-image>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getDocument, useDocument } from '@/composables'
import { AddImage } from '../components'
import { ImageThumb } from '@/components/layout/cards'
import store from '@/store'
export default {
  name: 'AlbumDetails',

  components: {
    AddImage,
    ImageThumb
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const loggedUser = computed(() => store.state.auth.loggedUser)
    const { error, document: album } = getDocument('albums', props.id)
    const { updateDoc } = useDocument('albums', props.id)

    const handleClick = async (i) => {
      await updateDoc({
        cover: i
      })
    }

    const viewImage = (img) => {
      console.log(img)
    }

    return { error, album, loggedUser, handleClick, viewImage }
  }
}
</script>

<style></style>
