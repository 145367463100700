<template>
  <div>
    <h1>Albums</h1>

    <albums :albums="albums"></albums>
    <create-album :loggedUser="loggedUser" />
  </div>
</template>

<script>
import store from '@/store'
import { computed } from '@vue/composition-api'
import { getCollection } from '@/composables'
import { Albums, CreateAlbum } from '../components'

export default {
  name: 'HomeAlbums',

  components: {
    CreateAlbum,
    Albums
  },

  setup() {
    const loggedUser = computed(() => store.state.auth.loggedUser)

    const { error, documents: albums } = getCollection('albums', [
      'userId',
      '==',
      loggedUser.value.uid
    ])

    return { error, albums, loggedUser }
  }
}
</script>

<style></style>
