<template>
  <div>
    <bottom-sheet :dialog="addAlbumSheet" @close="closeAddImageSheet">
      <v-form @submit.prevent="handleSubmit">
        <v-row no-gutters>
          <v-col cols="12">
            <app-text-field
              v-model="title"
              placeholder="Título"
            ></app-text-field>
            <app-text-field
              v-model="description"
              placeholder="Descrição"
            ></app-text-field>
            <image-input @selectedFile="selectedFile"></image-input>
          </v-col>

          <v-col>
            <app-btn
              :disabled="formError"
              type="submit"
              lIcon="check"
              color="primary"
              block
              >Criar</app-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </bottom-sheet>
    <app-btn-fab
      position="right-bottom"
      icon="plus"
      color="primary"
      @click="openAddImageSheet"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useStorage, useDocument } from '@/composables'
// import { fbTimestamp } from '@/plugins/firebase'
import utils from '@/utils/utils'
import store from '@/store'
import { BottomSheet, useDialogs } from '@/components/layout/dialogs'
import { ImageInput } from '@/components/layout/forms'

export default {
  name: 'AddImage',

  components: {
    BottomSheet,
    ImageInput
  },

  props: {
    album: {
      type: Object,
      required: true
    },
    loggedUser: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const title = ref('')
    const description = ref('')
    const albumId = props.id
    const file = ref(null)
    const fileError = ref(null)
    // const { error, addDoc } = useCollection('albums')
    const {
      dialog: addAlbumSheet,
      handleOpen: openAddImageSheet,
      handleClose: closeAddImageSheet
    } = useDialogs()

    const formError = computed(() => {
      return (
        file.value === '' || file.value === null || file.value === undefined
      )
    })

    const selectedFile = (item) => {
      console.log('Este é o arquivo', item.value)
      file.value = item.value
    }

    const { filePath, url, uploadImage } = useStorage()
    const { updateDoc } = useDocument('albums', props.album.id)

    const handleSubmit = async () => {
      store.dispatch('home/setOverlayLoading', true)
      if (file.value) {
        await uploadImage(file.value, props.loggedUser, 'albums')
        const newImage = {
          id: utils.uuid4(),
          title: title.value,
          description: description.value,
          filePath: filePath.value,
          coverUrl: url.value
        }
        await updateDoc({
          photos: [...props.album.photos, newImage]
        })
        store.dispatch('home/setOverlayLoading', false)
        closeAddImageSheet()
        title.value = ''
        description.value = ''
        file.value = null
      } else {
        fileError.value = 'Imagem inválida'
        file.value = null
        store.dispatch('home/setOverlayLoading', false)
      }
    }

    return {
      file,
      formError,
      // error,
      title,
      description,
      albumId,
      addAlbumSheet,
      openAddImageSheet,
      closeAddImageSheet,
      selectedFile,
      handleSubmit
    }
  }
}
</script>

<style></style>
